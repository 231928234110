import Keycloak from 'keycloak-js';
import type { KeycloakInitOptions } from 'keycloak-js';

const keycloak = new Keycloak({
  realm: 'csv', // realm as configured in Keycloak
  url: 'https://sso.cubevn-service.com/', // URL of the Keycloak server
  clientId: 'dashboard', // client id as configured in the realm in Keycloak
});

export const initOptions: KeycloakInitOptions = {
  onLoad: 'login-required',
  scope: 'openid offline_access',
  checkLoginIframe: false,
};
export default keycloak;
