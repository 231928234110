/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
// Import tailwind styles
import './static/assets/styles/global.css';
import React from 'react';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { Provider } from 'react-redux';
import { store } from './src/redux/store';

import keycloak from './src/services/keycloak';

export const wrapRootElement = ({ element }) => {
  return (
    <Provider store={store}>
      <ReactKeycloakProvider
        authClient={keycloak}
        initOptions={{
          onLoad: 'login-required',
          scope: 'openid',
        }}
      >
        {element}
      </ReactKeycloakProvider>
    </Provider>
  );
};
