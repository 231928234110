import { data } from 'autoprefixer';
import axiosClient from '../axiosClient';

const productApi = {
  getProducts(data: any) {
    const url = '/dashboard/api/products';
    return axiosClient.get(url);
  },
  getProductWithId(id: any) {
    const url = '/dashboard/api/product/id';
    return axiosClient.get(url, {
      params: {
        id,
      },
    });
  },
  createProduct(data: FormData) {
    const url = '/dashboard/api/product/create';
    return axiosClient.post(url, data);
  },
  updateProduct(data: FormData) {
    const url = '/dashboard/api/product/create';
    return axiosClient.post(url, data);
  },
};

export default productApi;
