import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import productApi from '../../../services/api/productApi';

export enum Status {
  idle,
  pending,
  fulfilled,
  rejected,
}

export interface ResponseStatus {
  status: Status;
  message: String;
}
export interface ProductState {
  products: any;
  createProductStatus: ResponseStatus;
}
const initialState: ProductState = {
  products: [],
  createProductStatus: {
    status: Status.idle,
    message: '',
  },
};
export const createProduct = createAsyncThunk(
  'product/createProduct',
  async (formData: FormData) => {
    if (!formData.get('name')) {
      throw new Error('Invalid data!');
    }
    const response = await productApi.createProduct(formData);
    return response.data;
  }
);
export const fetchProduct = createAsyncThunk(
  'product/fetchProduct',
  async () => {
    const response = await productApi.getProducts({});
    console.log(response);
    return response.data;
  }
);
export const getProductById = createAsyncThunk(
  'product/id',
  async (id: any) => {
    const response = await productApi.getProductWithId(id);
    console.log(response);
    return response.data;
  }
);

const productSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    // createProduct(state, action: any) {},
  },
  extraReducers: builder => {
    builder
      .addCase(createProduct.pending, (state, action: any) => {
        console.log('pen');

        state.createProductStatus = {
          message: 'pending',
          status: Status.pending,
        };
      })
      .addCase(createProduct.fulfilled, (state, action: any) => {
        state.createProductStatus = {
          message: 'Success',
          status: Status.fulfilled,
        };
      })
      .addCase(createProduct.rejected, (state, action: any) => {
        console.log(action);
        state.createProductStatus = {
          message: action.error.message,
          status: Status.rejected,
        };
      })
      .addCase(getProductById.pending, (state, action: any) => {
        // console.log('pen');
        // state.createProductStatus = {
        //   message: 'pending',
        //   status: Status.pending,
        // };
      })
      .addCase(getProductById.fulfilled, (state, action: any) => {
        // state.createProductStatus = {
        //   message: 'Success',
        //   status: Status.fulfilled,
        // };
      })
      .addCase(getProductById.rejected, (state, action: any) => {
        // console.log(action);
        // state.createProductStatus = {
        //   message: action.error.message,
        //   status: Status.rejected,
        // };
      })
      .addCase(fetchProduct.fulfilled, (state, action) => {
        state.products = action.payload['result'];
      })
      .addCase(fetchProduct.rejected, (state, action) => {
        console.log('asdaksjdakjsdhg');
      });
  },
});
const { actions, reducer } = productSlice;
export const productActions = productSlice.actions;
// Action creators are generated for each case reducer function

export default productSlice.reducer;
