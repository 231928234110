exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-admin-404-tsx": () => import("./../../../src/pages/admin/404.tsx" /* webpackChunkName: "component---src-pages-admin-404-tsx" */),
  "component---src-pages-admin-tsx": () => import("./../../../src/pages/admin/[...].tsx" /* webpackChunkName: "component---src-pages-admin-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-my-apps-index-tsx": () => import("./../../../src/pages/my-apps/index.tsx" /* webpackChunkName: "component---src-pages-my-apps-index-tsx" */),
  "component---src-templates-using-dsg-tsx": () => import("./../../../src/templates/using-dsg.tsx" /* webpackChunkName: "component---src-templates-using-dsg-tsx" */)
}

