module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"CSV DASHBOARD","short_name":"CSV DASHBOARD","start_url":"/","background_color":"#FFF","display":"standalone","icon":"src/images/brand-icon.png","icons":[{"src":"assets/images/dashboard_icon/dashboard_icon_32x32.png","sizes":"32x32","type":"image/png","purpose":"any"},{"src":"assets/images/dashboard_icon/dashboard_icon_48x48.png","sizes":"48x48","type":"image/png","purpose":"any"},{"src":"assets/images/dashboard_icon/dashboard_icon_72x72.png","sizes":"72x72","type":"image/png","purpose":"any"},{"src":"assets/images/dashboard_icon/dashboard_icon_96x96.png","sizes":"96x96","type":"image/png","purpose":"any"},{"src":"assets/images/dashboard_icon/dashboard_icon_128x128.png","sizes":"128x128","type":"image/png","purpose":"any"},{"src":"assets/images/dashboard_icon/dashboard_icon_144x144x.png","sizes":"144x144","type":"image/png","purpose":"any"},{"src":"assets/images/dashboard_icon/dashboard_icon_152x152.png","sizes":"152x152","type":"image/png","purpose":"any"},{"src":"assets/images/dashboard_icon/dashboard_icon_192x192.png","sizes":"192x192","type":"image/png","purpose":"any"},{"src":"assets/images/dashboard_icon/dashboard_icon_384x384.png","sizes":"384x384","type":"image/png","purpose":"any"},{"src":"assets/images/dashboard_icon/dashboard_icon_512x512.png","sizes":"512x512","type":"image/png","purpose":"any"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c661fe1888cbf379b8605794e2608c12"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"languages":["en","ja","vi"],"defaultLanguage":"en","siteUrl":"http://localhost:8000","i18nextOptions":{"fallbackLng":"en","supportedLngs":["en","ja","vi"],"defaultNS":"common","interpolation":{"escapeValue":false}},"pathBlacklist":["/assets/*"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-fontawesome-css/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-redux/gatsby-browser.js'),
      options: {"plugins":[],"pathToCreateStoreModule":"./src/state/createStore","serialize":{"space":0,"isJSON":true,"unsafe":false,"ignoreFunction":true},"cleanupOnClient":true,"windowKey":"__PRELOADED_STATE__"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/*"]},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
